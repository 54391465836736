import * as React from "react"
import { Suspense } from "react"
import type { HeadFC } from "gatsby"
import { Link } from 'gatsby'
import Layout from '../components/layout'
//import component list
import LandingBanner from '../components/landing-banner'
import ClientFeatureSlider from '../components/client-feature'
import LoadingSpinner from '../components/_loading-spinner'

const FourBucket = React.lazy(() => import('../components/four-bucket'))
const GlobalDuplexBanner = React.lazy(() => import('../components/global-duplex-banner'))
const FeatureList = React.lazy(() => import('../components/feature-list'))
const BlogFeature = React.lazy(() => import('../components/blog-feature'))
const QuoteFeature = React.lazy(() => import('../components/quote-feature'))
const NewsFeature = React.lazy(() => import('../components/news-feature'))
const ContainedBanner = React.lazy(() => import('../components/contained-banner'))
const ProductLanding = React.lazy(() => import('../components/product-landing'))
const FocusBanner = React.lazy(() => import('../components/focus-banner'))
const GraphData = React.lazy(() => import('../components/graph-data'))
const ThreeColumn = React.lazy(() => import('../components/three-column'))
const CenteredQuote = React.lazy(() => import('../components/centered-quote'))
const ImageSlider = React.lazy(() => import('../components/image-slider'))
const BenefitSlider = React.lazy(() => import('../components/benefit-slider'))
const ProductCompare = React.lazy(() => import('../components/two-bucket'))
const FormComponent = React.lazy(() => import('../components/forms-component'))
const VideoFeature = React.lazy(() => import('../components/video-focus'))
const FeaturedResource = React.lazy(() => import('../components/resource-feature'))
const MiniCaseStudySlider = React.lazy(() => import('../components/mini-case-study/mini-case-study-slider'))
const GlobalIntro = React.lazy(() => import('../components/global-intro'))
const CreativeComponent = React.lazy(() => import('../components/creative-component'))
const DuplexBladeStack = React.lazy(() => import('../components/h1-homepage-2024/h1-blade-stack'))
const EntrataStats = React.lazy(() => import('../components/h1-homepage-2024/h1-entrata-stats'))
const QuoteComponent = React.lazy(() => import('../components/h1-homepage-2024/h1-quote-component'))
const InfoGrid = React.lazy(() => import('../components/h1-homepage-2024/h1-q2-info-grid'))
const LogoDisplay = React.lazy(() => import('../components/h1-homepage-2024/h1-q2-logo-display'))
const Resources = React.lazy(() => import('../components/h1-homepage-2024/h1-resources'))
const RatingsGrid = React.lazy(() => import('../components/h1-homepage-2024/h1-ratings-grid'))


const ComponentContentArea = ({layoutPieces}) => {
    if (layoutPieces) {
      return layoutPieces?.map((layoutPiece, index) => {
        return ([
          layoutPiece.internal.type === 'ContentfulLandingBanner' && <LandingBanner layoutPiece={layoutPiece} key={index}/>,
          layoutPiece.internal.type === 'ContentfulPortfolioCarousel' && <ClientFeatureSlider layoutPiece={layoutPiece} key={index}/>,

          layoutPiece.internal.type === 'ContentfulSuiteCardsComponent' && <Suspense key={index} fallback={<LoadingSpinner />}><FourBucket layoutPiece={layoutPiece} /></Suspense>,
          layoutPiece.internal.type === 'ContentfulDuplexFeature' && <Suspense key={index} fallback={<LoadingSpinner />}><GlobalDuplexBanner layoutPiece={layoutPiece} /></Suspense>,
          layoutPiece.internal.type === 'ContentfulAttributeListComponent' && <Suspense key={index} fallback={<LoadingSpinner />}><FeatureList layoutPiece={layoutPiece} /></Suspense>,
          layoutPiece.internal.type === 'ContentfulBlogFeature' && <Suspense key={index} fallback={<LoadingSpinner />}><BlogFeature layoutPiece={layoutPiece} /></Suspense>,
          layoutPiece.internal.type === 'ContentfulQuoteDuplex' && <Suspense key={index} fallback={<LoadingSpinner />}><QuoteFeature layoutPiece={layoutPiece} /></Suspense>,
          layoutPiece.internal.type === 'ContentfulNewsFeature' && <Suspense key={index} fallback={<LoadingSpinner />}><NewsFeature layoutPiece={layoutPiece} /></Suspense>,
          layoutPiece.internal.type === 'ContentfulJoinTheTeamFeature' && <Suspense key={index} fallback={<LoadingSpinner />}><ContainedBanner layoutPiece={layoutPiece} /></Suspense>,
          layoutPiece.internal.type === 'ContentfulProductBanner' && <Suspense key={index} fallback={<LoadingSpinner />}><ProductLanding layoutPiece={layoutPiece} /></Suspense>,
          layoutPiece.internal.type === 'ContentfulProductFeature' && <Suspense key={index} fallback={<LoadingSpinner />}><FocusBanner layoutPiece={layoutPiece} /></Suspense>,
          layoutPiece.internal.type === 'ContentfulBenefitGraph' && <Suspense key={index} fallback={<LoadingSpinner />}><GraphData layoutPiece={layoutPiece} /></Suspense>,
          layoutPiece.internal.type === 'ContentfulBucketComponent' && <Suspense key={index} fallback={<LoadingSpinner />}><ThreeColumn layoutPiece={layoutPiece} /></Suspense>,
          layoutPiece.internal.type === 'ContentfulCenteredQuote' && <Suspense key={index} fallback={<LoadingSpinner />}><CenteredQuote layoutPiece={layoutPiece} /></Suspense>,
          layoutPiece.internal.type === 'ContentfulImageSliderComponent' && <Suspense key={index} fallback={<LoadingSpinner />}><ImageSlider layoutPiece={layoutPiece} /></Suspense>,
          layoutPiece.internal.type === 'ContentfulFeatureSliderComponent' && <Suspense key={index} fallback={<LoadingSpinner />}><BenefitSlider layoutPiece={layoutPiece} /></Suspense>,
          layoutPiece.internal.type === 'ContentfulProductComparison' && <Suspense key={index} fallback={<LoadingSpinner />}><ProductCompare layoutPiece={layoutPiece} /></Suspense>,
          layoutPiece.internal.type === 'ContentfulFormComponent' && <Suspense key={index} fallback={<LoadingSpinner />}><FormComponent layoutPiece={layoutPiece} /></Suspense>,
          layoutPiece.internal.type === 'ContentfulVideoFeature' && <Suspense key={index} fallback={<LoadingSpinner />}><VideoFeature layoutPiece={layoutPiece} /></Suspense>,
          layoutPiece.internal.type === 'ContentfulFeaturedResourceComponent' && <Suspense key={index} fallback={<LoadingSpinner />}><FeaturedResource layoutPiece={layoutPiece} /></Suspense>,
          layoutPiece.internal.type === 'ContentfulMiniCaseStudySlider' && <Suspense key={index} fallback={<LoadingSpinner />}><MiniCaseStudySlider layoutPiece={layoutPiece} /></Suspense>,
          layoutPiece.internal.type === 'ContentfulGlobalIntro' && <Suspense key={index} fallback={<LoadingSpinner />}><GlobalIntro layoutPiece={layoutPiece} /></Suspense>,
          layoutPiece.internal.type === 'ContentfulRichTextComponent' && <Suspense key={index} fallback={<LoadingSpinner />}><CreativeComponent layoutPiece={layoutPiece} /></Suspense>,
          layoutPiece.internal.type === 'ContentfulDuplexBladeStack' && <Suspense key={index} fallback={<LoadingSpinner />}><DuplexBladeStack layoutPiece={layoutPiece} /></Suspense>,
          layoutPiece.internal.type === 'ContentfulEntrataStatComponent' && <Suspense key={index} fallback={<LoadingSpinner />}><EntrataStats layoutPiece={layoutPiece} /></Suspense>,
          layoutPiece.internal.type === 'ContentfulFormattedQuote' && <Suspense key={index} fallback={<LoadingSpinner />}><QuoteComponent layoutPiece={layoutPiece} /></Suspense>,
          layoutPiece.internal.type === 'ContentfulInfoGrid' && <Suspense key={index} fallback={<LoadingSpinner />}><InfoGrid layoutPiece={layoutPiece} /></Suspense>,
          layoutPiece.internal.type === 'ContentfulLogoDisplay' && <Suspense key={index} fallback={<LoadingSpinner />}><LogoDisplay layoutPiece={layoutPiece} /></Suspense>,
          layoutPiece.internal.type === 'ContentfulOneByThreeImageGrid' && <Suspense key={index} fallback={<LoadingSpinner />}><Resources layoutPiece={layoutPiece} /></Suspense>,
          layoutPiece.internal.type === 'ContentfulRatingsGrid' && <Suspense key={index} fallback={<LoadingSpinner />}><RatingsGrid layoutPiece={layoutPiece} /></Suspense>,
        ])
      })
    }
}

export default ComponentContentArea
