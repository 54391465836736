import * as React from "react"
import { Link, graphql } from 'gatsby'
import Layout from '../components/layout'

import ComponentContentArea from '../templates/component-content-area'

export default function ComponentPage({ data, pageContext }) {
  const layoutPieces = data.contentfulComponentsPage.pageComponents

  return (
    <Layout>
      <ComponentContentArea layoutPieces={layoutPieces}/>
    </Layout>
  )
}

export const query = graphql`
  query ComponentsPageContent {
    contentfulComponentsPage {
      pageComponents {
        ... on Node {
          internal {
            type
          }
        }
        ... on ContentfulLandingBanner {
          ...LandingBanner
        }
        ... on ContentfulPortfolioCarousel {
          ...ClientFeature
        }
        ... on ContentfulSuiteCardsComponent {
          ...SuiteCards
        }
        ... on ContentfulDuplexFeature {
          ...DuplexFeature
        }
        ... on ContentfulAttributeListComponent {
          ...AttributeListComponent
        }
        ... on ContentfulBlogFeature {
          ...BlogFeature
        }
        ... on ContentfulQuoteDuplex {
          ...QuoteDuplex
        }
        ... on ContentfulNewsFeature {
          ...NewsFeature
        }
        ... on ContentfulJoinTheTeamFeature {
          ...JoinTheTeamFeature
        }
        ... on ContentfulProductBanner {
          ...ProductBanner
        }
        ... on ContentfulProductFeature {
          ...ProductFeature
        }
        ... on ContentfulBenefitGraph {
          ...BenefitGraph
        }
        ... on ContentfulBucketComponent {
          ...BucketComponent
        }
        ... on ContentfulCenteredQuote {
          ...CenteredQuote
        }
        ... on ContentfulImageSliderComponent {
          ...ImageSliderComponent
        }
        ... on ContentfulFeatureSliderComponent {
          ...FeatureSliderComponent
        }
        ... on ContentfulProductComparison {
          ...ProductComparison
        }
        ... on ContentfulFormComponent {
          ...FormsComponent
        }
        ... on ContentfulVideoFeature {
          ...VideoFocus
        }
        ... on ContentfulFeaturedResourceComponent {
          ...FeaturedResourceComponent
        }
        ... on ContentfulMiniCaseStudySlider {
          ...CaseStudySlider
        }
        ... on ContentfulGlobalIntro {
          ...GlobalIntro
        }
        ... on ContentfulRichTextComponent {
          ...RichTextComponent
        }
        ... on ContentfulDuplexBladeStack {
          ...H1BladeStack
        }
        ... on ContentfulEntrataStatComponent {
          ...H1EntrataStats
        }
        ... on ContentfulFormattedQuote {
          ...H1Quote
        }
        ... on ContentfulInfoGrid {
          ...H1InfoGrid
        }
        ... on ContentfulLogoDisplay {
          ...H1LogoDisplay
        }
        ... on ContentfulOneByThreeImageGrid {
          ...H1Resources
        }
        ... on ContentfulRatingsGrid {
          ...H1RatingsGrid
        }
      }
    }
  }
`
